import { uploadToGCS, uploadToS3 } from "./uploader";
import type { StorageProviderType } from "types/utils";

const apiLink = process.env.REACT_APP_API_URL;

const setCookie = (cname: string, cvalue: string, extime: number) => {
  var d = new Date();
  d.setTime(extime * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Strict;";
};

const getCookie = (cname: string) => {
  var name = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0).toString() === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

const generateBody = (params: URLSearchParams, prefix: string, object: any) => {
  if (typeof object == "object") {
    if (object === null || object === undefined) {
    } else if (Array.isArray(object)) {
      object.forEach((object, i) => {
        generateBody(params, `${prefix}[${i}]`, object);
      });
    } else {
      for (const key in object) {
        const value = object[key];
        if (value === null || value === undefined) {
          continue;
        }
        if (prefix) {
          generateBody(params, `${prefix}[${key}]`, value);
        } else {
          generateBody(params, key, value);
        }
      }
    }
  } else {
    params.append(prefix, object);
  }
};

export const apiCall = (
  url: string,
  method: "GET" | "POST" | "DELETE" = "GET",
  payload: any = null,
  token: string | undefined = undefined
) => {
  let finalUrl = apiLink + url;
  let headers: HeadersInit = {};
  let body: any = undefined;

  if (token === undefined) {
    headers["Authorization"] = "Bearer " + getCookie("admin-auth-token");
  } else if (token !== "") {
    headers["Authorization"] = "Bearer " + token;
  }

  if (method === "POST") {
    headers["Content-Type"] = "application/json";
  }

  if (payload) {
    if (method === "GET") {
      let params = new URLSearchParams();
      generateBody(params, "", payload);
      finalUrl += "?" + params.toString();
    } else {
      body = JSON.stringify(payload);
    }
  }

  return new Promise<any>((resolve, reject) => {
    fetch(finalUrl, {
      method,
      headers,
      body,
      cache: "no-cache",
    })
      .then((res) => res.json(), reject)
      .then(
        (res) => {
          if (res && res.error) {
            reject(res);
          } else {
            resolve(res);
          }
        },
        () => {
          resolve(null);
        }
      );
  });
};

export const setAuthCookie = (token: string) => {
  let parts = token.split(".");
  let payload = JSON.parse(atob(parts[1]));
  setCookie("admin-auth-token", token, payload["exp"]);
};

export const clearAuthCookie = () => {
  setCookie("admin-auth-token", "", 0);
};

export const createAndUploadFile = (
  storage: StorageProviderType,
  file: File,
  type: "image" | "audio" | "video",
  progressFunc?: (progress: number) => void
) => {
  if (progressFunc) {
    progressFunc(0);
  }
  let total = 0;
  const url =
    storage === "aws-s3"
      ? "/storage/aws_s3_signed_url"
      : "/storage/gcs_signed_url";
  const uploadMethod = storage === "aws-s3" ? uploadToS3 : uploadToGCS;
  return apiCall(url, "POST", {
    content_type: file.type,
    content_length: file.size,
  }).then((response) => {
    const signedURL = response.signed_url;
    const cloudFile = response.file_name;
    return new Promise<string>((resolve, reject) => {
      uploadMethod({
        signedURL,
        file: file,
        onComplete: () => resolve(cloudFile),
        onError: reject,
        onProgress: (progress) => {
          total += progress;
          if (progressFunc) {
            progressFunc((100 * total) / Math.max(file.size, 1));
          }
        },
      });
    });
  });
};
