import React from "react";
import type { StorageProviderType } from "types/utils";

export interface AppContextType {
  isLoggedIn: boolean;
  gcsURLBase: string;
  awsURLBase: string;
  defaultStorage: StorageProviderType;
  updateContext: (newContext: AppContextType) => void;
}

export const initialContext: AppContextType = {
  isLoggedIn: false,
  gcsURLBase: "",
  awsURLBase: "",
  defaultStorage: "aws-s3",
  updateContext: () => {},
};

export const AppContext = React.createContext<AppContextType>(initialContext);

export const useAppContext = () => React.useContext(AppContext);
export const useURLBase = () => {
  const context = useAppContext();
  return {
    "aws-s3": context.awsURLBase,
    gcs: context.gcsURLBase,
    youtube: "https://youtube.com/watch?v=",
  };
};
