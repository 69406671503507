import React from "react";
import { useDropzone } from "react-dropzone";

const typeAccepts = {
  image: {
    "image/png": [".png"],
    "image/jpeg": [".jpg", ".jpeg"],
  },
  audio: {
    "audio/mpeg": [".mp3"],
  },
  video: {
    "video/mp4": [".mp4"],
  },
};

export type FileType = keyof typeof typeAccepts;

interface Props {
  type: FileType;
  onDrop: (files: File[]) => void;
  file?: File;
  style?: React.CSSProperties;
}

const Dropzone = (props: Props) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: props.onDrop,
    accept: typeAccepts[props.type],
    multiple: false,
  });

  const text = React.useMemo(() => {
    if (props.file) {
      return props.file.name;
    } else if (isDragActive) {
      return "Drop the file here ...";
    }
    return "Drag 'n' drop a file here, or click to select a file";
  }, [isDragActive, props.file]);

  return (
    <div
      {...getRootProps()}
      style={{
        borderRadius: "3px",
        border: "1px dashed #ccc",
        padding: "20px",
        textAlign: "center",
        cursor: "pointer",
        backgroundColor: "#f5f5f5",
        boxSizing: "border-box",
        ...props.style,
      }}
    >
      <input {...getInputProps()} />
      {text}
    </div>
  );
};

export default Dropzone;
